import { j as u } from "./jsx-runtime-B6kdoens.js";
import { a as N, _ as m } from "./index-CWsRuimy.js";
import * as R from "react";
import { forwardRef as g, useState as I, useRef as v, createElement as b, useEffect as q } from "react";
import { a as B, b as M } from "./index-B5chcmfZ.js";
import { $ as T } from "./index-Cp-Nu9_j.js";
import { $ as H } from "./index-CX8l_p9w.js";
import { $ as A } from "./index-DaaF0TLE.js";
import { $ as C } from "./index-B--ic68G.js";
import { c as w } from "./utils-CJ9afRe1.js";
import { I as O } from "./Icon-VZwz_Tkb.js";
const S = "Switch", [z, re] = T(S), [D, F] = z(S), L = /* @__PURE__ */ g((t, o) => {
  const { __scopeSwitch: e, name: c, checked: a, defaultChecked: r, required: d, disabled: s, value: i = "on", onCheckedChange: p, ...k } = t, [n, h] = I(null), P = N(
    o,
    (l) => h(l)
  ), $ = v(!1), x = n ? !!n.closest("form") : !0, [f = !1, E] = B({
    prop: a,
    defaultProp: r,
    onChange: p
  });
  return /* @__PURE__ */ b(D, {
    scope: e,
    checked: f,
    disabled: s
  }, /* @__PURE__ */ b(C.button, m({
    type: "button",
    role: "switch",
    "aria-checked": f,
    "aria-required": d,
    "data-state": y(f),
    "data-disabled": s ? "" : void 0,
    disabled: s,
    value: i
  }, k, {
    ref: P,
    onClick: M(t.onClick, (l) => {
      E(
        (j) => !j
      ), x && ($.current = l.isPropagationStopped(), $.current || l.stopPropagation());
    })
  })), x && /* @__PURE__ */ b(X, {
    control: n,
    bubbles: !$.current,
    name: c,
    value: i,
    checked: f,
    required: d,
    disabled: s,
    style: {
      transform: "translateX(-100%)"
    }
  }));
}), U = "SwitchThumb", W = /* @__PURE__ */ g((t, o) => {
  const { __scopeSwitch: e, ...c } = t, a = F(U, e);
  return /* @__PURE__ */ b(C.span, m({
    "data-state": y(a.checked),
    "data-disabled": a.disabled ? "" : void 0
  }, c, {
    ref: o
  }));
}), X = (t) => {
  const { control: o, checked: e, bubbles: c = !0, ...a } = t, r = v(null), d = H(e), s = A(o);
  return q(() => {
    const i = r.current, p = window.HTMLInputElement.prototype, n = Object.getOwnPropertyDescriptor(p, "checked").set;
    if (d !== e && n) {
      const h = new Event("click", {
        bubbles: c
      });
      n.call(i, e), i.dispatchEvent(h);
    }
  }, [
    d,
    e,
    c
  ]), /* @__PURE__ */ b("input", m({
    type: "checkbox",
    "aria-hidden": !0,
    defaultChecked: e
  }, a, {
    tabIndex: -1,
    ref: r,
    style: {
      ...t.style,
      ...s,
      position: "absolute",
      pointerEvents: "none",
      opacity: 0,
      margin: 0
    }
  }));
};
function y(t) {
  return t ? "checked" : "unchecked";
}
const _ = L, G = W, J = R.forwardRef(({ className: t, checked: o, thumbIcon: e, children: c, ...a }, r) => /* @__PURE__ */ u.jsxs(
  _,
  {
    className: w(
      "peer bg-gray-50 inline-flex h-[20px] w-[41px] shrink-0 cursor-pointer items-center relative rounded-full border border-gray-neutral-80 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring data-[state=checked]:border-blue-100 focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-blue-10 data-[state=unchecked]:bg-gray-50",
      { "w-[80px]": !!c },
      t
    ),
    ...a,
    ref: r,
    children: [
      /* @__PURE__ */ u.jsx(
        "span",
        {
          "data-state": o ? "checked" : "unchecked",
          className: "text-sm data-[state=checked]:mr-auto data-[state=checked]:ml-1 data-[state=checked]:text-white data-[state=unchecked]:mr-1 data-[state=unchecked]:ml-auto max-w-[60px] truncate",
          children: c
        }
      ),
      /* @__PURE__ */ u.jsx(
        G,
        {
          className: w(
            "absolute pointer-events-none h-4 w-4 rounded-full bg-gray-500 shadow-lg ring-0 transition-transform data-[state=checked]:text-white flex justify-center items-center data-[state=checked]:translate-x-[1.375rem] data-[state=checked]:bg-blue-100 data-[state=unchecked]:translate-x-0.5",
            { "data-[state=checked]:translate-x-14": !!c }
          ),
          children: e && /* @__PURE__ */ u.jsx(O, { name: e, className: "w-3 h-3 text-inherit" })
        }
      )
    ]
  }
));
J.displayName = _.displayName;
export {
  J as S
};
